// mini-sab
.mini-sab {
	position: relative;

	.container {
		position: relative;
		display: flex;
		justify-content: center;
		max-width: none;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 0 15px;
			padding: 30px 0 15px 0;
			border-radius: 25px;
			background: $purple;

			@include media-breakpoint-up(xl) {
				padding: 30px 45px;
			}
		}

		// mini-sab-caption
		div.mini-sab-caption {
			position: absolute;
			left: -15px;
			bottom: calc(100% + 15px);
			display: flex;
			flex-direction: column;
			padding: 5px 20px;
			border-radius: 10px;
			background: $yellow;
			color: $purple;

			line-height: 1.15;
			text-transform: uppercase;
			font-family: $font-family-secondary;
			transform: rotate(-3deg);

			a {
				color: inherit;
				text-decoration: none;
				pointer-events: none;
			}

			.list-item-title {
				display: block;
				font-size: 24px;
				font-weight: 600;

				@include media-breakpoint-up(xl) {
					font-size: 36px;
				}
			}

			.list-item-suffix {
				font-size: 16px;
				font-weight: 500;

				@include media-breakpoint-up(xl) {
					font-size: 20px;
				}
			}
		}
	}
}

&.home {
	.mini-sab {
		&::before {
			content: url(/images/visual-garland-1.svg);
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;

			@include media-breakpoint-down(lg) {
				display: none;
				//transform: scale(0.5);
				//transform-origin: left;
			}
		}
	}
}
