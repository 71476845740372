& {
	font-size: 14px;
}

&.home {
	.main {
		position: relative;

		&::before {
			content: url(/images/visual-garland-2.svg);
			z-index: 3;
			position: absolute;
			top: 10%;
			left: 0;
			pointer-events: none;

			@include media-breakpoint-down(lg) {
				display: none;
				//transform: scale(0.5);
				//transform-origin: left;
			}
		}
	}

	&.home {
		.main {
			&::after {
				content: url(/images/visual-garland-3.svg);
				z-index: 3;
				position: absolute;
				left: 0;
				bottom: 35%;
				pointer-events: none;

				@include media-breakpoint-down(lg) {
					display: none;
					//transform: scale(0.5);
					//transform-origin: left;
				}
			}
		}
	}
}

// container
.container {
	.container-holder>.column {
		z-index: 5;
	}

	@media (min-width: 1280px) {
		max-width: 1210px;
	}

	&.container-wide {
		@media (min-width: 1366px) {
			max-width: 1310px;
		}
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-extra-large {
		@include media-breakpoint-up(xl) {
			max-width: 1140px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// headings
h1 {
	line-height: 1;
	text-transform: uppercase;
	text-shadow: rgba($pink, 1) 6px 0px 0px, rgba($pink, 1) 5.91686px 0.995377px 0px, rgba($pink, 1) 5.66974px 1.96317px 0px, rgba($pink, 1) 5.2655px 2.87655px 0px, rgba($pink, 1) 4.71532px 3.71022px 0px, rgba($pink, 1) 4.03447px 4.44106px 0px, rgba($pink, 1) 3.24181px 5.04883px 0px, rgba($pink, 1) 2.35931px 5.51667px 0px, rgba($pink, 1) 1.41143px 5.83163px 0px, rgba($pink, 1) 0.424423px 5.98497px 0px, rgba($pink, 1) -0.574341px 5.97245px 0px, rgba($pink, 1) -1.55719px 5.79441px 0px, rgba($pink, 1) -2.49688px 5.45578px 0px, rgba($pink, 1) -3.36738px 4.96596px 0px, rgba($pink, 1) -4.14455px 4.33852px 0px, rgba($pink, 1) -4.80686px 3.59083px 0px, rgba($pink, 1) -5.33596px 2.74364px 0px, rgba($pink, 1) -5.71718px 1.8204px 0px, rgba($pink, 1) -5.93995px 0.84672px 0px, rgba($pink, 1) -5.99811px -0.150428px 0px, rgba($pink, 1) -5.89004px -1.14341px 0px, rgba($pink, 1) -5.61874px -2.1047px 0px, rgba($pink, 1) -5.19172px -3.00766px 0px, rgba($pink, 1) -4.62082px -3.82727px 0px, rgba($pink, 1) -3.92186px -4.54081px 0px, rgba($pink, 1) -3.11421px -5.12852px 0px, rgba($pink, 1) -2.22026px -5.57409px 0px, rgba($pink, 1) -1.26477px -5.86518px 0px, rgba($pink, 1) -0.274238px -5.99373px 0px, rgba($pink, 1) 0.723898px -5.95617px 0px, rgba($pink, 1) 1.70197px -5.75355px 0px, rgba($pink, 1) 2.63288px -5.39147px 0px, rgba($pink, 1) 3.49082px -4.87998px 0px, rgba($pink, 1) 4.25202px -4.23324px 0px, rgba($pink, 1) 4.89538px -3.46919px 0px, rgba($pink, 1) 5.40307px -2.60899px 0px, rgba($pink, 1) 5.76102px -1.67649px 0px, rgba($pink, 1) 5.95932px -0.697531px 0px;
}

// paragraph
p {
	min-height: 1rem;
}

strong {
	font-weight: 700;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 20px;

	&:not(.card-overlay) {
		background: $purple;

		.card-caption {
			color: $white;

			.card-title {
				color: $yellow;
				font-size: 24px;
				text-transform: uppercase;
			}

			.card-subtitle {
				font-size: 16px;
				font-weight: 600;
				text-transform: uppercase;
			}
		}

		.card-buttons {
			.card-btn {
				@extend .link-arrow;
				box-shadow: none;
				padding: 0 !important;
				color: $yellow !important;
			}
		}
	}

	&.card-overlay {
		.card-img-overlay {
			display: flex;
			flex-direction: column;
			background: transparent;
			border-radius: 0;
			transition: background-color 0.5s;

			&::after {
				content: "\f138";
				position: absolute;
				top: 15px;
				right: 15px;
				color: $white;
				font-size: 32px;
				font-weight: 900;
				line-height: 1;
				font-family: $font-awesome;
			}

			.card-caption {
				display: flex;
				flex-direction: column;
				flex: 0;
				margin-top: auto;
				text-shadow: none;

				.card-title {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 0;
					background: url('/images/card-title-bg.svg') no-repeat;
					background-size: cover;
					aspect-ratio: 255 / 49;
					color: $white;
					font-size: 20px;
					line-height: 1.4;
					text-align: center;
					text-transform: uppercase;
					overflow: hidden;

					@include media-breakpoint-down(sm) {
						font-size: 16px;
					}
				}

				.card-subtitle {
					z-index: 1;
					order: -1;
					margin: 0 auto -2px 15px;
					padding: 5px 10px;
					border-radius: 6px;
					background: $yellow;
					color: $purple;
					font-size: 16px;
					font-weight: 600;
					line-height: 1.15;
					text-transform: uppercase;
					font-family: $font-family-secondary;

					@include media-breakpoint-down(sm) {
						font-size: 14px;
					}
				}

				.card-description {
					order: -2;
					display: none;
					padding: 15px 5px;

					ul {
						@extend .custom-list-extra;

						li {
							color: $white;

							&::before {
								color: $white !important;
							}
						}
					}
				}

				.card-buttons {
					display: none;
				}
			}
		}

		&:hover {
			.card-img-overlay {
				background-color: $purple;

				.card-caption {
					.card-description {
						display: block;
					}
				}
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 20px;
		}
	}

	.gallery-label {
		top: 15px;
		left: 0;
		padding: 5px 15px;
		background-color: $primary;
		border-radius: 0 99px 99px 0;
		color: $white;
	}
}

// link-arrow
.link-arrow {
	position: relative;
	display: inline-flex;
	align-items: center;
	color: $green;
	font-size: 20px;
	font-weight: 500;
	text-decoration: none !important;
	text-transform: uppercase;
	font-family: $font-family-secondary;

	&::after {
		content: "\f138";
		margin-left: 7px;
		color: inherit;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		transform: translateX(5px);
	}
}

// cta-badge
div.cta-badge {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 190px;
	border-radius: 25px 25px 25px 0;
	background-color: $green;
	border: 3px solid $yellow;
	box-shadow: 0 0 0 15px $green;
	aspect-ratio: 1;
	color: $yellow;
	text-align: center;
	text-transform: uppercase;
	font-family: $font-family-secondary;
	transition: 0.5s;
	overflow: hidden;

	a {
		color: inherit;
		text-decoration: none;
	}

	.list-item-title {
		display: block;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.15;
	}

	.list-item-suffix {
		position: absolute;
		left: 0;
		bottom: 0;
		display: inline-flex;
		align-items: center;
		padding: 5px 10px;
		border-radius: 0 10px 0 0;
		background-color: $yellow;
		color: $green;
		font-weight: 500;
		line-height: 1.15;
		transition: 0.5s;

		&::after {
			content: "\f138";
			margin-left: 4px;
			color: inherit;
			font-weight: 900;
			font-family: $font-awesome;
		}
	}

	&:hover {
		background-color: $purple;

		.list-item-suffix {
			color: $purple;
		}
	}
}

// faq-category
.page-block.faq-category {
	.faq-overview {
		.faq-category {
			margin-top: 0;

			.faq-items {
				border-top: 1px solid $purple;

				.faq-item {
					.faq-question {
						margin-bottom: 0;
						padding: 10px 20px 10px 0;
						border: none;
						border-bottom: 1px solid $purple;

						h3 {
							color: $purple;
							font-size: 20px;
							font-weight: 500;
							text-transform: uppercase;
						}

						.faq-icon {
							left: auto;
							right: 0;
							width: auto;
							line-height: 45px;

							i {
								&::before {
									content: '\f067';
								}
							}
						}
					}

					.faq-answer {
						margin-bottom: 0;
						padding: 10px;
						border: none;

						p:last-of-type {
							margin-bottom: 0;
						}
					}

					&.active {
						.faq-question {
							.faq-icon {
								i {
									&::before {
										content: '\f068';
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
