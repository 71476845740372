// old base file

// override source styling: https://api.tommybookingsupport.com/widgets/zoekenboek/css/default.css
.mini-sab .tommy-zeb-wrapper {
    float: none;
    width: auto;
}
.mini-sab .tommy-zeb-wrapper * { font-family: $font-family-base !important; font-size: 14px; float: none !important}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
    flex-wrap: wrap;
    float: none;
    background: none;
    color: $black;
    padding: 0 !important;
}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left label { color: $black;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { justify-content: center !important;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
    margin-right: 15px;
    padding-right: 0;
    border-right: none;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
	order: -1;
}
.mini-sab .tommy-zeb-wrapper-mini h2.ng-binding { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label { display: none;}

/* Accommodatietype */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types h2.ng-binding {
	position: relative;
    display: block;
    margin: 0 0 10px 0;
    color: $yellow;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
	font-family: $font-family-secondary !important;
    text-align: center;
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	&::after {
		content: "";
		z-index: 1;
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		right: 0;
		width: 0;
		height: 0;
		margin: 0 auto;
		border-top: 15px solid $purple;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select { /*max-width: 200px;*/ min-width: 200px; outline: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category select { width: 200px;}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input { line-height: 20px;}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select { padding: 5px; margin: 0; width: 100%;}
.mini-sab .tommy-zeb-period { display: flex !important;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin { margin-right: 15px;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label { float: none !important; display: block; position: relative;
	&::after {
		content: "";
		z-index: 1;
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		right: 0;
		width: 0;
		height: 0;
		margin: 0 auto;
		border-top: 15px solid $purple;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}
}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label, .mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label { margin-bottom: 10px !important; line-height: 30px; display: block; color: $yellow; font-size: 20px; font-weight: 500; font-family: $font-family-secondary !important; text-transform: uppercase; text-align: center; 	@include media-breakpoint-down(lg) {
	font-size: 16px;
}}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
    padding: 0;
    float: left;
    margin-bottom: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
    border-right: none;
    padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 8px 10px;
    height: 40px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input { width: 200px; outline: none;}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update {
    min-width: 130px;
    margin: 40px 0 0 0 !important;
    padding: 8px 20px;
    border-radius: 8px;
    border: 1px solid $yellow;
    background-color: $yellow;
    color: $purple;
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
	text-align: center;
    text-transform: uppercase;
	font-family: $font-family-secondary !important;
	box-shadow: 3px 3px 20px 0px rgba($black, 0.25);
}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update::after {
	content: '\f138';
	margin-left: 7px;
	font-weight: 900;
	font-family: $font-awesome;
}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update:hover {
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
}

/* collapse functionaliteit */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding {
	position: relative;
    display: block;
    margin: 0 0 10px 0;
    color: $yellow;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
	font-family: $font-family-secondary !important;
    text-align: center;
	text-transform: uppercase;

	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}

	&::before {
		content: "";
		z-index: 1;
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		right: 0;
		width: 0;
		height: 0;
		margin: 0 auto;
		border-top: 15px solid $purple;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
	}
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories h2.ng-binding::after { display: none;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse {
    display: flex;
    min-width: auto;
	background: #fff;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 8px;
    width: 50px !important;
    height: 40px;
    margin-top: 0 !important;
    margin-left: 5px;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list {
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 0 6px rgba($black, 0.15);
    border-radius: 2px;
    color: #000;
    width: 100%;
    max-width: 100%;
    min-width: 260px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-category-list .ng-scope { padding: 0;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category {
    flex-wrap: nowrap;
    margin: 5px 0;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-label {
    flex: 1 1 auto;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category .tommy-zeb-accommodation-person-category-choice {
    flex: 0 0 92px;
    align-self: center;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-choice .ng-binding {
    min-width: 20px;
    line-height: 22px;
    text-align: center;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range {
    background-color: $primary;
    color: #fff;
    line-height: 22px;
    transition: all 0.5s ease;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range:hover {
    background-color: $secondary;
    color: #fff;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-range span {
    line-height: 24px;
    top: 0!important;
}

.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories {
    height: 40px;
    padding: 9px 14px;
    color: #000;
    font-weight: 400;
    border-radius: 3px;
    text-transform: none;
    background-color: #fff;
    border: 1px solid #fff;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn { margin-top: 0 !important;}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-change-person-categories::after {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
    margin-left: 15px;
}

@media (min-width: 768px) and (max-width: 1199px) {

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}

}
@media (max-width: 767px) {

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { display: block;}
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
        border-bottom: none;
        padding: 0;
        margin: 0;
    }
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
		display: block;
		margin: 10px 0;
	}
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
    .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope { float: none; clear: both;}
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn { margin: 0 !important;}
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}
    .mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update { display: block;}

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types select { max-width: none;}
    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
        display: block !important;
        width: 100% !important;
    }

	.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update { margin-top: 15px !important;}
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { margin-right: 0;}

}
