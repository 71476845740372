// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 400px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 120px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	&::after {
		content: '';
		z-index: 1;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -16px;
		height: 74px;
		background: url('/images/visual-top-white.svg') no-repeat center;

		@include media-breakpoint-down(lg) {
			bottom: -1px;
			background-size: 100%;
			height: auto;
			aspect-ratio: 1500 / 37;
		}
	}

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		.item {
			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					margin-top: auto;

					.owl-caption {
						margin-bottom: 100px;
						color: $white;
						text-shadow: none;

						.owl-title {
							@extend h1;
							margin-bottom: 1rem;
						}

						.owl-btn {
							display: none;
						}
					}
				}
			}
		}

		// owl-dots
		.owl-dots {
			display: none;
		}

		// owl-video
		.owl-video-tn {
			display: none;
		}

		.owl-video-play-icon {
			z-index: 100;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 80px;
			height: 80px;
			margin: -40px 0 0 -40px;
			background: rgba($white, 0.5);
			border-radius: 100%;
			transition: 0.5s;
			transform: none;

			@include media-breakpoint-up(lg) {
				width: 160px;
				height: 160px;
				margin: -80px 0 0 -80px;
			}

			&:hover {
				background: rgba($white, 1);
			}

			&::after {
				content: '\f144';
				color: $primary;
				font-size: 30px;
				font-weight: 900;
				font-family: $font-awesome;

				@include media-breakpoint-up(lg) {
					font-size: 60px;
				}
			}
		}
	}

	&:not(.large) {

		// owl-nav
		.owl-nav {
			display: none;
		}
	}
}

&.groepen {
	.eyecatcher {
		&::after {
			background: url('/images/visual-top-purple.svg') no-repeat center;
		}
	}
}
