// footer
.footer {
	position: relative;
	margin-top: 6vh;

	// footer-outro-section
	.footer-outro-section {
		padding: 30px 0;

		// footer-logo
		.footer-logo {
			margin-top: -115px;

			@include media-breakpoint-down(md) {
				margin-top: -125px;
				text-align: center;
			}

			img {
				width: 100%;
				max-width: 278px;

				@include media-breakpoint-up(md) {
					max-width: 415px;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			@include media-breakpoint-down(md) {
				margin-top: 15px;
			}

			@include media-breakpoint-up(md) {
				margin-left: auto;
			}

			ul {
				margin: 0 -5px;
				justify-content: center;

				li {
					margin: 0 5px;
					font-size: 32px;

					a {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 60px;
						height: 60px;
						border-radius: 8px;
						background-color: $green;
						color: $white;
						font-size: 20px;
						text-align: center;
						text-decoration: none;

						&:hover {
							background-color: $purple;
						}
					}
				}
			}
		}
	}

	// footer-sitelinks-section
	.footer-sitelinks-section {
		margin: 30px 0;

		// footer-text
		.footer-text {
			h5 {
				text-transform: uppercase;
			}

			ul {
				padding: 0;
				list-style: none;

				li {
					line-height: 2;

					a {
						position: relative;
						display: inline-flex;
						align-items: center;
						padding: 0 0 0 24px;

						&::before {
							content: '\f138';
							position: absolute;
							left: 0;
							font-family: $font-awesome;
							font-weight: 900;
						}

						&:hover {
							color: $green;
						}
					}
				}
			}
		}
	}

	// footer-copyright-section
	.footer-copyright-section {
		padding: 15px 0 45px 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			li {
				color: $black;
				font-size: 12px;

				@include media-breakpoint-up(md) {
					margin-right: 30px;
				}

				a {
					color: $black;
					text-decoration: none;

					&:hover {
						color: $green;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

&.home,
&.groepen {
	.footer {
		margin-top: 0;
	}
}

&.home {
	.footer {
		&::before {
			content: url(/images/visual-garland-4.svg);
			z-index: 3;
			position: absolute;
			right: 0;
			bottom: 115%;
			pointer-events: none;

			@include media-breakpoint-down(lg) {
				display: none;
				//transform: scale(0.5);
				//transform-origin: right;
			}
		}
	}
}

&:not(.home) {
	.footer {
		border-top: 115px solid $pink;
	}
}
