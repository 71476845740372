// btn
.btn {
	display: inline-flex;
	align-items: center;
	border-radius: 8px;
	box-shadow: 0 0 8px rgba($black, 0.25);
	font-size: 20px;
	font-weight: 500;
	line-height: 1.4;
	text-transform: uppercase;
	font-family: $font-family-secondary;

	// btn-primary
	&.btn-primary {
		color: $yellow;

		&:hover {
			color: $yellow;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
			background-color: $purple;
			border-color: $purple;
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-back
.btn-back {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}

// btn-cta
.btn-cta {
	@extend .btn;
	@extend .btn-primary;
	padding: 12px 25px;
	font-size: 24px;
	line-height: normal;
	transform: rotate(-3deg);

	@include media-breakpoint-down(sm) {
		padding: 8px 15px;
		font-size: 20px;
	}

	&::after {
		content: '\f138';
		margin-left: 10px;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		transform: rotate(3deg);
	}
}
