// bg-*
// =========================================================================
section {
	&.bg-light {
		position: relative;

		&::before {
			content: '';
			display: block;
			height: 74px;
			margin-bottom: 30px;
			background: url('/images/visual-top-pink.svg') no-repeat center #fff;

			@include media-breakpoint-down(lg) {
				background-size: 100%;
				height: auto;
				aspect-ratio: 1500 / 37;
			}
		}

		&::after {
			content: '';
			display: block;
			height: 74px;
			margin-top: 30px;
			background: url('/images/visual-bottom-pink-mirrored.svg') no-repeat center #fff;

			@include media-breakpoint-down(lg) {
				background-size: 100%;
				height: auto;
				aspect-ratio: 1500 / 37;
			}
		}

		h1 {
			color: $pink;
			text-shadow: rgba($purple, 1) 6px 0px 0px, rgba($purple, 1) 5.91686px 0.995377px 0px, rgba($purple, 1) 5.66974px 1.96317px 0px, rgba($purple, 1) 5.2655px 2.87655px 0px, rgba($purple, 1) 4.71532px 3.71022px 0px, rgba($purple, 1) 4.03447px 4.44106px 0px, rgba($purple, 1) 3.24181px 5.04883px 0px, rgba($purple, 1) 2.35931px 5.51667px 0px, rgba($purple, 1) 1.41143px 5.83163px 0px, rgba($purple, 1) 0.424423px 5.98497px 0px, rgba($purple, 1) -0.574341px 5.97245px 0px, rgba($purple, 1) -1.55719px 5.79441px 0px, rgba($purple, 1) -2.49688px 5.45578px 0px, rgba($purple, 1) -3.36738px 4.96596px 0px, rgba($purple, 1) -4.14455px 4.33852px 0px, rgba($purple, 1) -4.80686px 3.59083px 0px, rgba($purple, 1) -5.33596px 2.74364px 0px, rgba($purple, 1) -5.71718px 1.8204px 0px, rgba($purple, 1) -5.93995px 0.84672px 0px, rgba($purple, 1) -5.99811px -0.150428px 0px, rgba($purple, 1) -5.89004px -1.14341px 0px, rgba($purple, 1) -5.61874px -2.1047px 0px, rgba($purple, 1) -5.19172px -3.00766px 0px, rgba($purple, 1) -4.62082px -3.82727px 0px, rgba($purple, 1) -3.92186px -4.54081px 0px, rgba($purple, 1) -3.11421px -5.12852px 0px, rgba($purple, 1) -2.22026px -5.57409px 0px, rgba($purple, 1) -1.26477px -5.86518px 0px, rgba($purple, 1) -0.274238px -5.99373px 0px, rgba($purple, 1) 0.723898px -5.95617px 0px, rgba($purple, 1) 1.70197px -5.75355px 0px, rgba($purple, 1) 2.63288px -5.39147px 0px, rgba($purple, 1) 3.49082px -4.87998px 0px, rgba($purple, 1) 4.25202px -4.23324px 0px, rgba($purple, 1) 4.89538px -3.46919px 0px, rgba($purple, 1) 5.40307px -2.60899px 0px, rgba($purple, 1) 5.76102px -1.67649px 0px, rgba($purple, 1) 5.95932px -0.697531px 0px;
		}
	}

	&.bg-dark {
		position: relative;

		&::before {
			content: '';
			display: block;
			height: 74px;
			margin-bottom: 30px;
			background: url('/images/visual-bottom-white-mirrored.svg') no-repeat center;

			@include media-breakpoint-down(lg) {
				background-size: 100%;
				height: auto;
				aspect-ratio: 1500 / 37;
			}
		}

		&::after {
			content: '';
			display: block;
			height: 74px;
			margin-top: 30px;
			background: url('/images/visual-top-white-mirrored.svg') no-repeat center;

			@include media-breakpoint-down(lg) {
				background-size: 100%;
				height: auto;
				aspect-ratio: 1500 / 37;
			}
		}

		h1 {
			color: $purple;
			text-shadow: rgba($yellow, 1) 6px 0px 0px, rgba($yellow, 1) 5.91686px 0.995377px 0px, rgba($yellow, 1) 5.66974px 1.96317px 0px, rgba($yellow, 1) 5.2655px 2.87655px 0px, rgba($yellow, 1) 4.71532px 3.71022px 0px, rgba($yellow, 1) 4.03447px 4.44106px 0px, rgba($yellow, 1) 3.24181px 5.04883px 0px, rgba($yellow, 1) 2.35931px 5.51667px 0px, rgba($yellow, 1) 1.41143px 5.83163px 0px, rgba($yellow, 1) 0.424423px 5.98497px 0px, rgba($yellow, 1) -0.574341px 5.97245px 0px, rgba($yellow, 1) -1.55719px 5.79441px 0px, rgba($yellow, 1) -2.49688px 5.45578px 0px, rgba($yellow, 1) -3.36738px 4.96596px 0px, rgba($yellow, 1) -4.14455px 4.33852px 0px, rgba($yellow, 1) -4.80686px 3.59083px 0px, rgba($yellow, 1) -5.33596px 2.74364px 0px, rgba($yellow, 1) -5.71718px 1.8204px 0px, rgba($yellow, 1) -5.93995px 0.84672px 0px, rgba($yellow, 1) -5.99811px -0.150428px 0px, rgba($yellow, 1) -5.89004px -1.14341px 0px, rgba($yellow, 1) -5.61874px -2.1047px 0px, rgba($yellow, 1) -5.19172px -3.00766px 0px, rgba($yellow, 1) -4.62082px -3.82727px 0px, rgba($yellow, 1) -3.92186px -4.54081px 0px, rgba($yellow, 1) -3.11421px -5.12852px 0px, rgba($yellow, 1) -2.22026px -5.57409px 0px, rgba($yellow, 1) -1.26477px -5.86518px 0px, rgba($yellow, 1) -0.274238px -5.99373px 0px, rgba($yellow, 1) 0.723898px -5.95617px 0px, rgba($yellow, 1) 1.70197px -5.75355px 0px, rgba($yellow, 1) 2.63288px -5.39147px 0px, rgba($yellow, 1) 3.49082px -4.87998px 0px, rgba($yellow, 1) 4.25202px -4.23324px 0px, rgba($yellow, 1) 4.89538px -3.46919px 0px, rgba($yellow, 1) 5.40307px -2.60899px 0px, rgba($yellow, 1) 5.76102px -1.67649px 0px, rgba($yellow, 1) 5.95932px -0.697531px 0px;
		}

		a.link-arrow {
			color: $yellow !important;
		}

		.form {
			padding: 0;

			@include media-breakpoint-up(xl) {
				padding: 0;
			}

			border: none;

			.form-control-label {
				color: $yellow;
				font-size: 15px;
				font-weight: 500;
				text-transform: uppercase;
				font-family: $font-family-secondary;
			}

			button[type=submit] {
				@extend .link-arrow;
				background-color: $yellow;
				color: $purple;
			}
		}
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	overflow: hidden;

	h1 {
		margin-bottom: 1.5rem;
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}

	// image-collection
	.image-collection {
		z-index: -1;
		width: 1060px;

		@include media-breakpoint-up(md) {
			margin-left: 45px;
		}

		&.grid {
			.grid-items {
				margin: 0;
				align-items: flex-start;
				justify-content: flex-start;

				.item {
					display: none;
					max-width: 530px;
					margin: 0;
					padding: 0;

					@include media-breakpoint-down(sm) {
						max-width: 100vw;
					}

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					&:nth-child(-n+2) {
						display: block;
					}

					&:nth-child(1) {
						transform: rotate(3deg);
					}

					&:nth-child(2) {
						margin-left: -30px;
						transform: rotate(-3deg);

						@include media-breakpoint-down(sm) {
							margin-top: -30px;
						}

						@include media-breakpoint-up(md) {
							margin-top: -115px;
							margin-left: -100px;
						}
					}

					.card {
						border: none;
						border-radius: 25px;

						.card-img-overlay {
							display: none;
						}
					}
				}
			}
		}
	}

	// cta-badge
	div.cta-badge {
		@include media-breakpoint-down(sm) {
			margin: 0 auto;
		}

		@include media-breakpoint-up(md) {
			position: absolute;
			top: -30px;
		}
	}
}

&.home {
	.lead-section {
		margin-bottom: 30px;
		padding: 15px 0;

		@include media-breakpoint-up(md) {
			padding-top: 45px;
		}

		@include media-breakpoint-up(xl) {
			padding: 115px 0 30px 0;
		}
	}
}

&.groepen {
	.lead-section {
		position: relative;
		margin-top: 0;
		padding-top: calc(6vh + 74px);

		&::before {
			content: '';
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 74px;
			background: url('/images/visual-bottom-purple-mirrored.svg') no-repeat center;

			@include media-breakpoint-down(lg) {
				bottom: -1px;
				background-size: 100%;
				height: auto;
				aspect-ratio: 1500 / 37;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

&.groepen {
	.content-section {
		&.bg-dark {
			margin-bottom: 0;

			&::after {
				background: url('/images/visual-bottom-purple-mirrored.svg') no-repeat center $pink;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card;
		}

		// custom-four
		&.custom-four {
			.grid-items {
				margin: 0 -10px -20px -10px;

				.item {
					margin-bottom: 20px;
					padding: 0 10px;

					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(3);
					}
				}
			}
		}
	}

	// link
	div.link {
		margin-top: 20px;
		text-align: right;

		a.link {
			@extend .link-arrow;
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	overflow-x: clip;

	&.bg-light {
		padding-bottom: 30px;

		&::before {
			margin-bottom: 0;
		}

		&::after {
			z-index: 2;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			margin-top: 0;
			background-color: transparent;
		}
	}

	// collection
	.collection {
		.card {
			@extend .default-card;
		}

		&.grid {
			.grid-items {
				align-items: flex-start;

				.item {
					&:nth-child(1) {
						margin-top: 30px;
						transform: rotate(-3deg);
					}

					&:nth-child(3) {
						transform: rotate(3deg);

						@include media-breakpoint-up(lg) {
							margin-top: 45px;
						}
					}

					.card {
						&.card-overlay {
							.card-title {
								@include font-size($h3-font-size);
								background-image: url('/images/card-title-large-bg.svg');
								aspect-ratio: 311 / 66;
							}

							.card-description {
								padding: 15px;
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 360px;
		max-height: 720px;

		@include media-breakpoint-up(xl) {
			height: 100vh;
		}

		.item {
			height: 75vh !important; // overide
			min-height: 360px;
			max-height: 720px;
			padding: unset; // overide
			align-items: flex-end;

			@include media-breakpoint-up(xl) {
				height: 100vh !important; // overide
			}

			&::before {
				content: '';
				z-index: 50;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 74px;
				margin-top: -1px;
				background: url('/images/visual-bottom-white.svg') no-repeat center;

				@include media-breakpoint-down(lg) {
					background-size: 100%;
					height: auto;
					aspect-ratio: 1500 / 37;
				}
			}

			&::after {
				content: '';
				z-index: 50;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 74px;
				margin-bottom: -1px;
				background: url('/images/visual-top-white.svg') no-repeat center;

				@include media-breakpoint-down(lg) {
					background-size: 100%;
					height: auto;
					aspect-ratio: 1500 / 37;
				}
			}

			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					max-width: 1030px;

					.owl-caption {
						max-width: 730px;
						margin-bottom: 40px;
						text-align: left;
						text-shadow: none;

						.owl-title {
							@extend h1;
							margin-bottom: 40px;
						}

						.owl-btn {
							@extend .btn-cta;
						}
					}
				}
			}
		}

		// owl-*
		.owl-nav,
		.owl-dots {
			display: none;
		}
	}
}

&.home {
	.banner-section {
		.owl-carousel {
			.item {
				&::before {
					display: none;
				}
			}
		}
	}
}

// photoalbum-section
// =========================================================================
.photoalbum-section {
	padding-bottom: 6vh;

	&.bg-dark {
		&::after {
			z-index: 2;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			margin-top: -1px;
			background: url('/images/visual-bottom-purple.svg') no-repeat center;

			@include media-breakpoint-down(lg) {
				background-size: 100%;
				height: auto;
				aspect-ratio: 1500 / 37;
			}
		}
	}

	// collection
	.collection {
		margin-top: 45px;
		padding: 0 15px;

		&.grid {
			.grid-items {
				margin: 0 -10px -20px -10px;

				.item {
					margin-bottom: 20px;
					padding-left: 10px;
					padding-right: 10px;
				}

				.card {
					border: none;
					border-radius: 20px;
					transform: rotate(-7deg);
					background: none;

					.card-img-overlay {
						display: none;
					}
				}
			}
		}
	}
}

// faq-section
// =========================================================================
.faq-section {
	padding-top: calc(6vh + 74px);
	padding-bottom: 30px;

	@include media-breakpoint-down(md) {
		padding-top: 6vh;
		padding-bottom: 115px;
	}

	&.bg-light {

		&::before,
		&::after {
			display: none;
		}
	}

	.container {
		@include media-breakpoint-up(md) {
			max-width: 750px;
		}
	}

	// faq-overview
	.faq-overview {
		margin: 20px 0;
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
