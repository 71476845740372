// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&::after {
		content: '';
		z-index: -1;
		position: absolute;
		top: calc(100% - 24px);
		left: 0;
		right: 0;
		height: 74px;
		background: url('/images/visual-bottom-white.svg') no-repeat center;
		filter: drop-shadow(0 8px 4px rgba($black, 0.1));
		transition: 0.5s;

		@include media-breakpoint-down(lg) {
			top: calc(100% - 1px);
			background-size: 100%;
			height: auto;
			aspect-ratio: 1500 / 37;
		}
	}

	&.sticky {
		box-shadow: $shadow;

		&::after {
			display: none;
		}
	}

	.container,
	.container-fluid {
		padding-left: 10px;
		padding-right: 10px;

		.container-holder {
			margin-left: -10px;
			margin-right: -10px;

			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					padding: 10px;
					flex-flow: row wrap;
				}

				@include media-breakpoint-up(xl) {
					padding: 20px 30px 10px 30px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 48px;
			height: 48px;
			padding: 0;
			outline: none;
			border: none;
			border-radius: 100%;
			background: $purple;
			color: $white;
			font-size: 24px;
			transition: 0.5s;

			i {
				line-height: 48px;
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background: $green;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		z-index: 90;
		position: absolute;
		width: 240px;
		transition: 0.5s;

		@include media-breakpoint-down(lg) {
			top: 10px;
			left: 68px;
		}

		@include media-breakpoint-up(xl) {
			top: 15px;
			left: 30px;
			width: 334px;
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			@include media-breakpoint-up(xl) {
				top: 5px;
				width: 130px;
			}
		}
	}

	// menu-default
	.menu-default {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				@include media-breakpoint-down(lg) {
					margin-top: 15px;
				}

				>.nav-item {
					font-size: 16px;
					font-weight: 500;
					text-transform: uppercase;
					font-family: $font-family-secondary;

					>.nav-link {
						display: flex;
						align-items: center;
						color: $black;

						&:hover {
							color: $purple;
						}
					}

					&.active {
						>.nav-link {
							color: $purple;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						border-radius: 0;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								padding: 0.25rem 0;
								color: $black;

								&:hover {
									color: $purple;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $purple;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			justify-content: flex-end;

			.navbar-nav {
				>.nav-item {
					margin: 0 5px;
					font-size: 16px;
					font-weight: 500;
					text-transform: uppercase;
					font-family: $font-family-secondary;

					>.nav-link {
						color: $black;

						&:hover {
							color: $purple;
						}
					}

					&.active {
						>.nav-link {
							color: $purple;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $purple;
								}
							}

							&.active {
								.nav-link {
									color: $purple;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		margin-left: auto;

		@include media-breakpoint-up(xl) {
			margin-left: 20px;
		}

		a {
			@extend .btn;
			@extend .btn-secondary;
			padding-left: 1.1rem !important;
			padding-right: 1.1rem !important;
			font-weight: 600 !important;
			transform: rotate(-3deg);

			&::after {
				content: '\f138';
				margin-left: 6px;
				font-weight: 900;
				font-family: $font-awesome;
			}

			&:hover {
				transform: rotate(3deg);
			}
		}
	}
}

&.navbar-collapse-active,
&.sticky {
	.header {
		.logo {
			@include media-breakpoint-down(lg) {
				width: 105px;
			}
		}
	}
}
